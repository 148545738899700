.projects-container {
    /* display: flex; */
    /* background-color: #f1f1f1; */
    /* border: 1px solid #ccc; */
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.selectors {
    height: auto;
    background-color: #727272;
}

/* Rewrite mui Card Style */
.selector {
    display: flex;
    height: auto;
    width: 22vh;
    white-space: nowrap;
    margin: 1vh !important;
    border-radius: 0.5vw !important;
    /* padding-bottom: 0px !important; */
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    color: #FFF !important;
    background-color: #000 !important;
    border: solid 3px #000 !important;
}

.project-icon {
    max-width: 2vw;
    max-height: 2vw;
    margin: 1vh;
    position: relative;
    float: left;
}

.MuiCardContent-root {
    padding: 0 !important;
}

.flicking-panel.thumb.has-background-primary.active .MuiButtonBase-root{
    border: 2px solid #009b1a !important;
}

.visualizers {
    height: 100%;
}

.current-viz {
    width: 100%;
    height: 80%;
    margin: 0px;
    padding: 0 5vw 0 5vw;
}

.visualizer {
    text-align: center;
    margin: 1vw 2vw 2vw 2vw;
    padding: 0 20vw 2vw 20vw;
    border-radius: 1vw !important;
    height: 100% !important;
    color: #FFF !important;
    background-color: #000 !important;
    border: solid 3px #9c9c9c !important;
}

.projet-small-title {
    font-size: 0.7vmax;
    padding: 2vh 0;
    color: #FFF;
    position: relative;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
    color: #FFF !important;
}

@media only screen and (orientation:portrait) and (max-width: 720px) {
    .MuiCardContent-root {
        padding: 0 !important;
    }
    
    .selector {
        border-radius: 1.3vh !important;
        display: grid;
        height: auto;
        width: 12vh;
    }

    .project-icon {
        float: none;
    }

    .project-icon img {
        max-width: 4vh;
        max-height: 4vh;
    }

    .projet-small-title {
        display: none;
    }

    /* .visualizers {
        height: 100%;
    } */

    .visualizer {
        margin: 1.5vh 0 1.5vh 0;
        border-radius: 2.5vh !important;
        padding: 0 2vh 0 2vh;
        /* padding: 0 1vw 1vw 1vw; */
    }

}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .MuiCardContent-root {
        padding: 0 !important;
    }
    
    .selector {
        border-radius: 1.3vh !important;
        display: grid;
        height: auto;
        width: 12vh;
    }

    .project-icon {
        max-width: 5vh;
        max-height: 5vh;
        float: none;
    }

    .project-icon img {
        margin: 0;

    }

    .projet-small-title {
        display: none;
    }

    .visualizers {
        height: 90%;
    }

    .visualizer {
        margin: 1.5vh 0 1.5vh 0;
        border-radius: 2.5vh !important;
        padding: 0 2vh 0 2vh;
    }

    .current-viz {
        height: 90%;
    }

}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .MuiCardContent-root {
        padding: 0 !important;
    }
    
    .selector {
        border-radius: 1vw !important;
        display: grid;
        height: auto;
        width: 22vh;
    }

    .project-icon {
        max-width: 5vh;
        max-height: 5vh;
        margin: 1vh;
        float: none;
    }

    .projet-small-title {
        font-size: 1.5vmax;
        display: none;
    }

    .visualizers {
        height: 90%;
    }

    .visualizer {
        border-radius: 1vw !important;
        /* padding: 0 4vh 4vh 4vh; */
        margin: 1vw 0 0 0;
        padding: 0 1vw 0 1vw;
        border: solid 1.5px #9c9c9c !important;
    }

    .current-viz {
        height: 85%;
        padding: 0 5vw 0 5vw;

    }

}
