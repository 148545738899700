.paper-plane {
  background-color: #3B2C85;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.paper-plane-container {
  position: relative;
}

.frame {
  width: 40%;
  height: 200px;
  /* margin: 15% auto 0; */
  position: absolute; 
}

svg {
  max-width: 100%;
  height: auto;
  display: block;
}

/**
 * Paper Plane
 */
 
/*Paper Plane: Container*/
.plane-container {
  width: 12vh;
  margin: 0px auto;
  z-index: 99;
}
 /*Paper Plane: Image*/
.plane {
  width: 100%;
  height: 60px;
}

/*Paper Plane: Animation*/
.plane-container {
  -webkit-animation: paper-plane-scoping 2s alternate infinite;
  -moz-animation: paper-plane-scoping 2s alternate infinite;
  animation: paper-plane-scoping 2s alternate infinite;

  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes paper-plane-scoping {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    transform: translateY(100px);
  }
}

.plane {
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  -webkit-animation: paper-plane-soaring 4s forwards infinite;
  -moz-animation: paper-plane-soaring 4s forwards infinite;
  animation: paper-plane-soaring 4s forwards infinite;
}
@keyframes paper-plane-soaring {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  60% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

/**
 * Clouds
 */
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* overflow: hidden; */
  z-index: 3;
}

.cloud {
  position: absolute;
  top: 20%;
  width: 15vh;
  right: 0;
  opacity: 1;
}

.cloud.front {
  z-index: 9;
}

.cloud.distant {
  z-index: 1;
}

.cloud.background {
  z-index: 1;
}

/*Cloud Sizing*/
.cloud.smaller {
  margin-right: 400px;
  width: 5vh;
  margin-top: 50px;
}

.cloud.small {
  margin-right: 200px;
  width: 7vh;
}

.cloud.big {
  width: 12vh;
  margin-top: 50px;
  margin-right: 150px;
}

.cloud.massive {
  width: 15vh;
  margin-top: 20px;
  margin-right: 0px;
}


/*Cloud: Animation*/
.cloud {
  -webkit-animation-name: cloud-movement;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;

  -moz-animation-name: cloud-movement;
  -moz-animation-timing-function: linear;
  -moz-animation-direction: forwards;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;

  animation-name: cloud-movement;
  animation-timing-function: linear;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-duration: 8s;
}

.slow {
  -webkit-animation-duration: 9.2s;
  -moz-animation-duration: 9.2s;
  animation-duration: 9.2s;
}

.slower {
  -webkit-animation-duration: 11.2s;
  -moz-animation-duration: 11.2s;
  animation-duration: 11.2s;
}

.slowest {
  -webkit-animation-duration: 13.5s;
  -moz-animation-duration: 13.5s;
  animation-duration: 13.5s;
}

.super-slow {
  -webkit-animation-duration: 20.5s;
  -moz-animation-duration: 20.5s;
  animation-duration: 20.5s;
}

@keyframes cloud-movement {
  0% {
    opacity: 0.1;
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    transform: translateX(300px);
  }
  10% {

    opacity: 0.7;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-1000px);
    -moz-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }
}