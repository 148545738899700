.mobile {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  background-color: #fff; 
  transition: width 0.5s ease; 
  overflow-x: hidden; 
  padding-top: 60px;
  transform: translateX(0);
}

.mobile-nav-opened {
  width: 100%; 
}

.mobile-nav-closed {
  width: 0;
}

.close-icon {
  font-size: 24px;
}

.mobile-options {
  padding: 15vh;
}

.mobile-option {
  font-size: 3vh;
  margin: 3vh 0px;
  padding: 3vh;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: width 0.3s ease; 
  font-family: "LemonMilkLight";
  /* padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s; */
}

.mobile-option a {
  text-decoration: none;
  color: rgb(1, 1, 155);
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
  .mobile {
    padding: 25px;
  }

  .mobile-option {
    font-size: 1.9vw;
  }
}