body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "EarlyGameBoy";
  src: local("EarlyGameBoy"),
   url("./assets/fonts/early_gameboy/Early\ GameBoy.ttf") format("truetype");
}

@font-face {
  font-family: "FatWandals";
  src: local("FatWandals"),
   url("./assets/fonts/fat_wandals/FatWandals_PERSONAL.ttf") format("truetype");
}

@font-face {
  font-family: "WholeCar";
  src: local("WholeCar"),
   url("./assets/fonts/wholecar_white/WholecarWhite_PERSONAL_USE_ONLY.otf") format("truetype");
}

@font-face {
  font-family: "Marsneveneksk";
  src: local("Marsneveneksk"),
   url("./assets/fonts/marsneveneksk/MARSNEVENEKSK-Clean.otf") format("truetype");
}

@font-face {
  font-family: "KGHAPPY";
  src: local("KGHAPPY"),
   url("./assets/fonts/kg_happy/KGHAPPY.ttf") format("truetype");
}

/* Social Media Icons font */ 
@font-face {
  font-family: "SocialShapes";
  src: local("SocialShapes"),
   url("./assets/fonts/social_shapes/Social_Shapes.ttf") format("truetype");
}

@font-face {
  font-family: "ElegantTypewriter";
  src: local("ElegantTypewriter"),
   url("./assets/fonts/elegant_typewriter/ELEGANT_TYPEWRITER_Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Antihero";
  src: local("Antihero"),
   url("./assets/fonts/antihero/Antihero.ttf") format("truetype");
}

@font-face {
  font-family: "MrTaxi";
  src: local("MrTaxi"),
   url("./assets/fonts/mr_taxi/MR_TAXI_regular.ttf") format("truetype");
}

@font-face {
  font-family: "GoodBrush";
  src: local("GoodBrush"),
   url("./assets/fonts/good_brush/Good_Brush.ttf") format("truetype");
}

@font-face {
  font-family: "SprayPaint";
  src: local("SprayPaint"),
   url("./assets/fonts/octin_spraypaint/octin\ spraypaint\ a\ rg.otf") format("truetype");
}

@font-face {
  font-family: "DoctorGlitch";
  src: local("DoctorGlitch"),
   url("./assets/fonts/doctor_glitch/Doctor\ Glitch.otf") format("truetype");
}

@font-face {
  font-family: "PersonalServices";
  src: local("PersonalServices"),
   url("./assets/fonts/personal_services/Personal\ Services.ttf") format("truetype");
}

@font-face {
  font-family: "ColoredPencil";
  src: local("ColoredPencil"),
   url("./assets/fonts/lt_colored_pencil/LT\ Colored\ Pencil.otf") format("truetype");
}

@font-face {
  font-family: "Blade";
  src: local("Blade"),
   url("./assets/fonts/blade_runner_font/BLADRMF_.TTF") format("truetype");
}

@font-face {
  font-family: "LemonMilkLight";
  src: local("LemonMilkLight"),
   url("./assets/fonts/lemon_milk/LEMONMILK-Light.otf") format("truetype");
}

@font-face {
  font-family: "LemonMilkMedium";
  src: local("LemonMilkMedium"),
   url("./assets/fonts/lemon_milk/LEMONMILK-Medium.otf") format("truetype");
}

.loading-page {
  /* background: linear-gradient(180deg, #1C2269 50%, #18144F 100%) !important; */
  background: rgb(0,0,0);
  /* background: -moz-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(7,7,7,1) 50%, rgba(14,14,14,1) 75%, rgba(25,25,25,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(7,7,7,1) 50%, rgba(14,14,14,1) 75%, rgba(25,25,25,1) 100%);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(7,7,7,1) 50%, rgba(14,14,14,1) 75%, rgba(25,25,25,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#191919",GradientType=1); */
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
}

.loading-prompt {
  font-family: "Antihero";
  color: #ff4500;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: auto;
  padding: auto;
  font-size: 2vw;
}

.MuiCircularProgress-circle.MuiCircularProgress-circleIndeterminate {
  stroke: #FF4500;
}
