.desktop-only {
    display: block;
}

.portable-only {
    display: none;
}

.project-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 16px 0px;
    /* padding: 8px 0px; */
    /* box-shadow: 0px 0px 100px 200px rgba(0,0,0,.5); */
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
}

.project-table-container {
    /* display: table;  */
    /* width: 100%;  */
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.project-photo {
    max-width: 85%;
    max-height: 85%;
    margin: auto;
    padding: 30px;
    max-height: 500px;
    max-width: 600px;
}

.project-title {
    font-size: 5vh;
    padding: 2vh 0 1vh 0;
    font-family: "SprayPaint";
    color: white;
    margin: auto;
    text-align: center;
}

.project-order {
    font-size: 3vh;
}

/* .project-tags {
    display: inline-block;
    line-break: normal;
    margin: 1vh;
    padding: 1vh;
    max-width: 100%;
    word-break: keep-all;
} */

.project-desc {
    color: #FFF;
    line-height: normal;
    margin: 3vh 0 0 0;
    font-size: 0.9vw;
}

.project-languages {
    color: #FFF;
    font-size: 0.85vw;
    font-weight: bold;
    padding: 2vh 0 0 0;
    margin: 2vh 0 1vh 0;
}

.center-chart-legend-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-1b95g7zMuiPieArc-root {
    stroke: transparent !important;
    stroke-width: 0 !important;
}

.css-1mhcdve-MuiPieArc-root {
    stroke: transparent !important;
    stroke-width: 0 !important;
}

.project-link {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

/* .css-bhp9pd-MuiPaper-root-MuiCard-root {
    background-color: #0d2330 !important;
} */

.tag {
    white-space: nowrap;
    overflow-wrap: normal;
    word-break: keep-all;
    line-break: anywhere;
    padding: 2vh !important;
    /* margin: 0 1vh 0 1vh !important; */
    align-items: "center" !important;
    color: #FFF !important;
    font-size: 0.8vw !important;
    background-color: rgba(7, 182, 245, 0.336) !important;
    border-radius: 25px !important;
    text-align: 'center' !important;
}

.project-content-desktop {
    display: block;
}

.project-content-mobile {
    display: none;
}

.chart-desktop {
    display: block;
}

.chart-mobile {
    display: none;
}

.chart-mobile-data {
    font-size: 1.5vh;
    text-align: left;
    padding-top: 1vh;
}

.project-action-btn {
    margin-top: 4vh;
    margin-bottom: 10px;
    padding: 2vh;
    width: 250px;
    border-radius: 5px;
    color: #cacaca;
    font-size: 0.7vw;
    background-color: #272828;
}

.project-action-btn:hover {
    color: #FFF;
    background-color: #3f3f3f;
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .project-photo {
        width: 70%;
    }

    .project-card {
        flex-direction: column-reverse;
    }

    .project-table-container {
        /* display: table;  */
        /* width: 100%;  */
        height: 65vh !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .project-card-section-1 {
        height: 20%;
        width: 100%;
    }
    
    .project-card-section-2 {
        height: 25%;
        width: 100%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
    
    .project-card-section-3 {
        height: 30%;
        width: 100%;
        /* padding: 0 3vh 0 3vh; */
        /* overflow: hidden; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .project-card-section-4 {
        height: 50%;
        width: 70%;
    }
    
    .project-card-section-5 {
        height: 10%;
        width: 100%;
    }

    .project-title {
        font-size: 4vh;
        padding: 2vh 0 1vh 0;
    }

    .project-order {
        font-size: 2.5vh;
    }

    .project-desc {
        padding: 0 !important;
        margin: 0 1vh 0 1vh;
        font-size: 1.8vh;
    }

    .tag {
        font-size: 1.5vh !important;
        margin: 0 !important;
    }

    .project-languages {
        color: #FFF;
        font-size: 1.7vh;
        font-weight: bold;
        padding: 2vh 0 0 0;
        margin: 1vh 0 0 0;
    }

    .project-content-desktop {
        display: none;
    }

    .project-content-mobile {
        display: block;
    }

    .chart-desktop {
        display: none;
    }
    
    .chart-mobile {
        display: block;
        margin: 5vw 0 5vw 0;
        /* padding: 0 3vw 0 3vw; */
    }
    
    .project-action-btn {
        /* margin-top: 1vh; */
        margin-top: 0;
        position: relative;
        /* right: 0; */
        bottom: 10;
        font-size: 1.7vh;
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .project-photo {
        width: 70%;
    }

    .project-card {
        flex-direction: column-reverse;
    }

    .project-table-container {
        /* display: table;  */
        /* width: 100%;  */
        height: 65vh !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .project-card-section-1 {
        height: 10%;
        width: 100%;
    }
    
    .project-card-section-2 {
        height: 20%;
        width: 100%;
        margin-top: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .project-card-section-3 {
        height: 40%;
        width: 100%;
        /* padding: 0 5vh 0 5vh; */
        margin-top: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .project-card-section-4 {
        height: 70%;
        width: 70%;
    }
    
    .project-card-section-5 {
        height: 10%;
        width: 100%;
    }

    .project-title {
        font-size: 3vw;
        padding: 2vh 0 1vh 0;
    }

    .project-order {
        font-size: 1.5vw;
    }

    .project-desc {
        padding: 0 !important;
        margin: 8vh 10vh 0 10vh;
        /* padding: 1vh; */
        font-size: 1.5vw;
        text-align: center;
        line-height: normal;
    }

    .tag {
        font-size: 1.2vw !important;
        padding: 1vw !important;
        margin: 0 !important;
    }

    .project-languages {
        color: #FFF;
        font-size: 1.25vw;
        font-weight: bold;
        /* padding: 4vh 0 0 0; */
        margin: 2vh 0 0 0;
    }

    .project-content-desktop {
        display: none;
    }

    .project-content-mobile {
        display: block;
    }

    .chart-desktop {
        display: none;
    }
    
    .chart-mobile {
        display: block;
        margin: 1vw 5vw 0 5vw;
        padding: 0 5vw 0 5vw;
    }

    .project-action-btn {
        margin: 0;
        /* padding-top: 0; */
        /* margin-top: 1vh; */
        position: relative;
        /* right: 0; */
        bottom: 10;
        font-size: 1.7vh;
    }
}