html,
body {
  margin: 0;
  font-family: arial, helvetica;
  line-height: 0px !important;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

header {
  color: #fff;
  text-align: center;
  padding: 0.8vh;
  /* background-color:rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #556270; */

}

footer {
  color: #fff;
  text-align: center;
  padding: 10px;
  color: #000;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  margin: 0px 25px;

  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

button {
  width: 50px;
  padding: 8px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.btnGroup {
  position: absolute;
  bottom: 19vh;
  right: 14vh;
  z-index: 2;
}

a.Navigation-Anchor {
  margin-bottom: 20px !important;
  padding: 5px !important;  
  /* Animation */
  transition: transform .2s !important;
  display: none !important;
}

a.Navigation-Anchor.active {
  background-color: rgb(70, 97, 141) !important;
  transform: scale(1.5) !important;
}

.flicking-arrow-next.is-circle {
  background-color: #000 !important;
}

.flicking-arrow-prev.is-circle {
  background-color: #000 !important;
}

.flicking-arrow-disabled.is-circle {
  background-color: rgb(10 10 10 / 10%) !important;
}

.arrow {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1.5s infinite alternate;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  max-width: 15vh;
  max-height: auto;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}

@keyframes action {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(-3px);
  }
}

.top_frame {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  max-width: 15vh;
  max-height: auto;
  transition: transform 0.7s ease-in-out;
}

.top_frame:hover {
  transform: rotate(360deg);
}


html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  font-weight: normal !important;
  margin: 0;
}

.important-info {
  color: #FF4500;
}

.title h1 {
  text-align: center;
  font-size: 10vw;
  font-family: "WholeCar";
  background: rgba(255, 255, 255, 0.0);
}

.page-setup {
  width: 100%;
}

.home {
  display: flex;
  flex-direction: column;
  margin: auto;
}


@media only screen and (max-width: 512px) {
  .home {
    width: 100%;
  }

  .title h1 {
    font-size: 50px;
  }
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
  .home {
    width: 90%;
  }

  .btnGroup {
    bottom: 15vh;
    right: 10vh;
  }

  .arrow {
    max-width: 12vh;
  } 

  .top_frame {
    max-width: 12vh;
  }

  .flicking-arrow-next.is-circle {
    background-color: transparent !important;
    z-index: 3;
  }

  .flicking-arrow-next.is-circle::before {
    background-color: #000 !important;
  }

  .flicking-arrow-next.is-circle::after {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle {
    background-color: transparent !important;
    z-index: 3;
  }

  .flicking-arrow-prev.is-circle::before {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle::after {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle.flicking-arrow-disabled::before {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-prev.is-circle.flicking-arrow-disabled::after {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-next.is-circle.flicking-arrow-disabled::before {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-next.is-circle.flicking-arrow-disabled::after {
    background-color: #A1A1A1 !important;
  }

}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
  .btnGroup {
    bottom: 12vw;
    right: 8vw;
  }

  .arrow {
    max-width: 10vw;
  } 

  .top_frame {
    max-width: 10vw;
  }

  .flicking-arrow-next.is-circle {
    background-color: transparent !important;
    z-index: 3;
  }

  .flicking-arrow-next.is-circle::before {
    background-color: #000 !important;
  }

  .flicking-arrow-next.is-circle::after {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle {
    background-color: transparent !important;
    z-index: 3;
  }

  .flicking-arrow-prev.is-circle::before {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle::after {
    background-color: #000 !important;
  }

  .flicking-arrow-prev.is-circle.flicking-arrow-disabled::before {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-prev.is-circle.flicking-arrow-disabled::after {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-next.is-circle.flicking-arrow-disabled::before {
    background-color: #A1A1A1 !important;
  }

  .flicking-arrow-next.is-circle.flicking-arrow-disabled::after {
    background-color: #A1A1A1 !important;
  }
}