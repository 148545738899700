.flicking-desktop {
    display: block;
}

.flicking-mobile {
    display: none;
}

.exp-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

/* Rewrite mui Card Style */
.exp {
    display: flex;
    flex-direction: column;
    border-radius: 1vw !important;
    max-width: 30vw;
    height: auto;
    inline-size: fit-content;
    margin: 2vw;
    padding: 0.5vw 1vw 0.5vw 1vw;
    align-items: center;
    text-align: center;
    color: #FFF !important;
    background-color: #1b1f25 !important;
    box-shadow: 0px 10px 0px 0px rgba(0, 0, 0, 0.42) !important;
}

.role-name {
    font-family: 'GoodBrush';
    color: #ff0040 !important;
    padding: 3vh 12vh 3vh 12vh;
    font-size: 4vh;
    word-break: keep-all;
}

.company-name {
    font-family: 'KGHAPPY';
    color: #fdafc3;
    font-size: 2.5vh;
    padding-bottom: 15px;
}

.exp-location {
    font-family: 'ElegantTypewriter';
    font-size: 1.5vh;
}

h4.exp-details {
    font-family: 'ElegantTypewriter';
    font-size: 2vh;
}

.flicking-pagination {
    top: 0;
    position: relative !important;
}

.flicking-pagination-scroll {
    width: 100% !important;
    height: 20px !important;
    bottom: 0px !important;
}

.flicking-pagination-bullet {
    background-color: #77777799 !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.flicking-pagination-bullet.active {
    background-color: #ff004099 !important;
}

/* 
.experience-arrow.flicking-arrow-disabled::before, .experience-arrow.flicking-arrow-disabled::after {
    background-color: #860122 !important;
}

.flicking-arrow-prev::before, .flicking-arrow-next::after {
    background-color: #ff0040 !important;
}

.flicking-arrow-next.is-outside {
    background-color: #FFF !important;
}

.flicking-arrow-prev.is-outside {
    background-color: #FFF !important;
}

.flicking-arrow-disabled.is-outside {
    background-color: rgb(10 10 10 / 10%) !important;
} 
*/

.experience-notice {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    font-size: 1.5vh;
    margin: auto;
    padding: 2.5vh 0 1.5vh 0;
    text-align: center;
}

.exp-stack {
    margin: 10px !important;
    padding: 10px !important;
    background-color: #222831 !important;
    border-radius: 1vw !important;
    width: 4%;
    height: 4%;
}

.exp-logo {
    width: 15%;
    height: 15%;
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .flicking-desktop {
        display: none;
    }
    
    .flicking-mobile {
        display: block;
    }

    .exp {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 6vw;
        padding: 4vw;
        max-width: 90vw;
        border-radius: 4vw !important;
    }

    .role-name {
        padding: 3vh 35vh 3vh 35vh;
        font-size: 4.5vh;
    }

    h4.exp-details {
        font-size: 1.5vh;
    }

    .exp-location {
        font-size: 1.5vh;
    }

    .experience-notice {
        padding: 2vh 1vh 1vh 1vh;
        font-size: 1.5vmax;
        text-align: center;
        line-height: normal;
    }

    .exp-stack {
        width: 4%;
        height: 4%;
        border-radius: 4vw !important;
    }
    
    .exp-logo {
        width: 20%;
        height: 20%;
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .flicking-desktop {
        display: none;
    }
    
    .flicking-mobile {
        display: block;
    }

    .exp {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 50vw;
        padding: 0;
        margin: 0.7vw 5vw 2vw 5vw;
        border-radius: 4vw !important;
        padding: 0;
    }

    .role-name {
        padding: 7vh 20vh 0 20vh;
        font-size: 3.5vw;
    }

    .company-name {
        font-size: 2vw;
    }

    h4.exp-details {
        font-size: 1.3vw;
    }

    .exp-location {
        font-size: 1.3vw;
    }

    .experience-notice {
        padding: 2vh 1vh 1vh 1vh;
        font-size: 1.2vmax;
        text-align: center;
        line-height: normal;
    }

    .exp-stack {
        /* margin: 0; */
        padding: 5px !important;
        border-radius: 4vh !important;
        width: 3%;
        height: 3%;
    }
    
    .exp-logo {
        width: 10%;
        height: 10%;
    }
}