.toggle-container {
  width: 54px;
  height: 24px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  position: relative;
}

.dialog-button {
  font-family: "LemonMilkMedium";
  font-size: 1.3vh;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #002b49;
  color: white;
  padding: 6px 10px;
  border-radius: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  /* min-width: 35px; */
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 26px;
  bottom: -2px;
  color: orangered;
  transition: all 0.3s ease;
}

.disabled {
  background-color: darkblue;
  color: white;
  left: 1px;
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
  .toggle-container {
    width: 70px;
    height: 32px;
    transform: scale(1.1);
  }

  .dialog-button {
    font-size: 1.8vh;
    padding: 8px 12px;
    border-radius: 3px;
    width: 38px;
    max-width: 46px;
    height: 38px;
    left: 34px;
  }

  .disabled {
    left: 1px;
  }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
  .dialog-button {
      font-size: 1.5vw;
  }
  
}