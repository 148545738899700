.web {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
}

.initials h2 {
    font-size: 2.5vh;
    color: navy;
}

.initials-k {
    font-family: "KGHAPPY";
}

.initials-a {
    font-family: "FatWandals";
}

.web-option a {
    font-size: 1.3vh;
    line-height: 1.5rem;
    font-family: "LemonMilkLight";
    text-decoration: none;
    color: navy;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 1vw 0 1vw; 
    opacity: 1;

    /* & :hover {
        color: #f54100;
    } */

    &.active {
        font-family: "LemonMilkMedium";
    }
}

.bg-yellow-hightlight {
    width: auto;
    height: auto;
    background-image: url("../../../../../assets/icons/yellow_highlight_lower_opacity.png");
}

.option-icon {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.web-option:last-child {
    margin-right: 0px;
}

.lng-toggle {
    position: absolute;
    top: 10px;
    right: 7vh;
}

@media only screen and (max-width: 1024px) {
    .initials h2 {
        font-size: 5vh;
    }
}