.desktop-only {
    display: block;
}

.portable-only {
    display: none;
}

.explore-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 30px;
}

.explore-title {
    font-family: "WholeCar";
    font-size: 6vh;
    padding: 7vh 5vh 0 5vh;
    color: #ff5010;
}

.explore-btn {
    text-align: center;
    margin-bottom: 4vh;
    font-family: "EarlyGameBoy";
    font-size: 2.25vh;
    color: #000;
    width: 100%;
}

.explore-btn:active {
    background-color: #FF4500;
}

.explore-link:hover {
    color: #FF4500;
}

@media only screen and (max-width: 1024px) {
    
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .desktop-only {
        display: none;
    }

    .portable-only {
        display: block;
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .desktop-only {
        display: none;
    }

    .portable-only {
        display: block;
    }

    .explore-title {
        padding: 0;
        font-size: 5vw;
    }

    .explore-btn {
        font-size: 1.75vw;
        margin-bottom: 3vh;
    }

    .explore-container {
        margin: 0;
    }
}