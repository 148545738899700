.btn {
  position: absolute;
  right: 4vw;
  top: 3vh;
  /* transform: translate(-50%, -50%); */
  width: 45px;
  cursor: pointer;
  z-index: 15;
  transform: scale(0.7);
}

.menu-span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  border: 3px solid;
  border-color: white;
  background: black;
  height: 12px;
  transition: all .3s;
  position: relative;
}

.active .menu-span+.menu-span {
  margin-top: 10px;
  background: navy;
}

.not-active .menu-span+.menu-span {
  margin-top: 5px;
}

.active .menu-span:nth-child(1) {
  animation: ease .7s top forwards;
  background: navy;
}

.not-active .menu-span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.active .menu-span:nth-child(2) {
  animation: ease .7s scaled forwards;
  background: navy;
}

.not-active .menu-span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.active .menu-span:nth-child(3) {
  animation: ease .7s bottom forwards;
  background: navy;
}

.not-active .menu-span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 22px;
    transform: rotate(0);
  }

  100% {
    top: 22px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }

  50% {
    top: 22px;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 22px;
    transform: rotate(0);
  }

  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }

  50% {
    bottom: 22px;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
  .btn {
    right: 4vw;
    top: 1vh;
    transform: scale(0.55);
  }
  
}