.header {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: 50px;
    margin-left: 50px;
}

.menu {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.language-toggle {
    justify-content: flex-end;
}

.MuiAppBar-colorPrimary {
    background-color: white !important;
}

.MuiAppBar-root {
    flex-direction: unset !important;
}

.MuiToolbar-regular {
    min-height: 48px !important;
}

.menu-icon {
    font-size: 24px;
}

.mobile-menu {
    display: none;
}

.web-menu {
    display: flex;
}

.logo {
    display: flex;
    font-size: 36px;
    color: blue;
    font-weight: 800;
}

@media only screen and (max-width: 1024px) {
    .mobile-menu{
        display: flex;
    }
    .header {
        display: none;
        margin: 0;
        padding: 0;
    }
    .MuiAppBar-root {
        display: none !important;
    }
    .logo{
        font-size: 24px;
    }
}