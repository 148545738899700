.ai-section {
    position: relative;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    padding-top: 15vh;
}

h1.ai-section-title {
    font-family: 'KGHAPPY';
    font-size: 4vmax;
}

p.ai-section-description {
    color: #FFF !important;
}

.ai-section-content {
    padding: 1vmax 0 7vmax 0;
    max-width: 70%;
}

.ai-section-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 200px;
    background-color: #020007;
    border: 4px solid #d8d8d8;
    /* box-shadow: 0px 7px 1px #; */
    border-radius: 15px;
    z-index: 2;
    margin: 4vmax 1vmax 4vmax 1vmax;
    transition: all 0.2s ease;
    pointer-events: auto;
}

.ai-card-title {
    color: #FFF;
    font-size: 2.5vmax;
    line-break: normal;
    line-height: normal;
    font-family: 'LemonMilkMedium';
    border: 0.1px solid #d8d8d8;
    padding:  1.5vmax 3vmax 1.5vmax 3vmax;
    margin: 0;
}

.ai-card-description {
    color: #FFF;
    line-break: normal;
    line-height: 2rem;
    font-size: large;
    font-family: 'Ubuntu';
    border: 0.1px solid #d8d8d8;
    padding:  1vmax 3vmax 1vmax 3vmax;
    margin: 0;
}

.ai-card-topics-title {
    color: #FFF;
    font-family: 'LemonMilkLight';
}

.ai-card-tags {
    padding:  1vmax 3vmax 1.5vmax 3vmax;
    margin: 0;
    color: #FFF;
    line-break: normal;
    /* line-height: 2rem; */
    margin: 0;
    font-family: 'LemonMilkLight';
}

.ai-card-tag {
    font-family: 'Ubuntu';
    padding: 1vmax;
    margin: 0 !important;
    color: #FFF !important;
    line-height: normal;
    white-space: nowrap;
    overflow-wrap: normal;
    word-break: keep-all;
    background-color: rgb(0, 54, 75) !important;
    border-radius: 15px !important;
}
