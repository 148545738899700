.cards-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transform: scale(1);
}

.cards {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.35));
    position: absolute;
    top: 0px;
    right: 0px;
    /* FIREFOX ERROR */
    margin-top: 7vh;
    /* bottom: 50px; */
    left: 0px;
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
}

.card {
    padding: 0 20% 0 20%;
    width: 100%;
    height: 100%;
}

.paper-card {
    /* margin: 0 10% 0 10%; */
    width: inherit;
    height: inherit;
    background-color: white;
    border-radius: 0.25rem;
    overflow: hidden;
    /* padding-top: 2%; */
    background: repeating-linear-gradient(#0000 0 calc(1.2rem - 1px), #66afe1 0 1.2rem) right bottom /100% 100%, linear-gradient(red 0 0) 30px 0/2px 100% #fff;
    background-repeat: no-repeat;
    line-height: 1.2rem;
    -webkit-mask: radial-gradient(circle .8rem at 2px 50%, #0000 98%, #000)0 0/100% 2.4rem;
}

.card:before {
    display: flex;
    content: "";
    position: sticky;
    bottom: 100%;
    right: 0;
    height: 1.5vh;
    width: 100%;
    transform: rotate(180deg);
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/23618/rip.svg) bottom;
    background-size: 200%;
}

.card:after {
    display: flex;
    content: "";
    position: sticky;
    top: 100%;
    right: 0;
    height: 2vh;
    width: 100%;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/23618/rip.svg) bottom;
    background-size: 200%;
}

.card_image img {
    width: 50%;
    height: 50%;
    object-fit: inherit;
    pointer-events: none;
}

.card_image_h {
    display: block;
}

.card_image_v {
    display: none;
}

h1.card_location,
h2.card_title,
h2.card_initials,
h2.card_year,
p {
    margin: 1.2rem 0;
    color: #000;
}

h1.card_location {
    font-family: 'ColoredPencil';
    font-size: 3vh;
    padding: 0px;
    margin: 0px;
}

h2.card_title {
    font-size: 4vh;
    line-height: 4vh;
    font-family: 'ColoredPencil';
}

h2.card_year {
    font-size: 2.4vh;
    line-height: 0.4vh;
    font-family: 'ColoredPencil';
}

h2.card_initials {
    font-size: 2rem;
    font-family: 'Antihero';
    color: orangered;
}

p.card_text {
    padding-top: 10px;
    font-family: 'ColoredPencil';
    font-size: 4vh;
    line-height: 3vh;
    padding: 2vw;
}

p.card_link {
    font-family: 'ColoredPencil';
    font-size: 2.5vh;
    line-height: 2.4rem;
    color: darkblue;
}

p.card_link:hover {
    color: blue;
    text-decoration: underline;
}

p.card_link:visited {
    color: indigo;
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .cards {
        margin-top: 0;
    }
    
    .card {
        padding: 5%;
    }

    .paper-card {
        padding: 2% 10% 0 10%;
    }
    
    h1.card_location {
        font-size: 2.5vh;
    }

    .card_image img {
        width: 55%;
        height: 55%;
    }

    .card_image_h {
        display: none;
    }

    .card_image_v {
        display: block;
        padding: 0;
        /* margin: 0 3vh 0 3vh; */
    }

    p.card_text {
        font-size: 7vw;
        padding: 2vw 7vw 2vw 7vw;
    }

    p.card_link {
        font-size: 2vh;
        margin-top: 0;
        line-height: normal;
    }
}

@media only screen and (orientation:portrait) and (max-width: 720px) {
    .card {
        padding: 5%;
    }
    
    .paper-card {
        padding: 2.5% 5% 0% 10%;
    }
    
    .card_image img {
        width: 60%;
        height: 60%;
    }

    h2.card_initials {
        margin-top: 0;
        line-height: 2rem;
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .cards {
        /* bottom: 0px; */
    }
    
    .paper-card {
        padding: 2.5% 5% 2.5% 10%;
    }

    h2.card_initials {
        font-size: 2.5vw;
        margin: 0;
    }

    h2.card_title {
        font-size: 3vw;
        padding: 0;
        margin: 2vw 0 1vw 0;
    }

    h1.card_location {
        font-size: 2vw;
    }

    .card_image img {
        width: 45%;
        height: 45%;
    }

    .card_image_h {
        display: block;
    }

    .card_image_v {
        display: none;
    }

    p.card_link {
        font-size: 1.7vw;
        line-height: 0;
        margin: 0;
    }
}
