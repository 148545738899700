.worm {
  margin: 0px;
  height: 100%;
  overflow: hidden;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(20, 20, 20, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(20, 20, 20, 1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(20, 20, 20, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgb(20, 20, 20) 0%,
    rgb(0, 0, 0)
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(20, 20, 20, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgb(20, 20, 20) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}