.contact {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.contact-container {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
    padding-top: 0px;
}

.contact-input {
    width: 40vh;
    margin: 5px;
    padding: 10px;
    border-radius: 7px;
    /* font-size: 0.7vw; */
}

.form-status {
    font-family: 'SprayPaint';
    color: #FFF;
    font-size: 1vw;
    padding: 10px;
    margin: 10px;
}

.success {
    color: #20b802;
}

.error {
    color: #d62121;
}

textarea .comments-textarea {
    resize: none !important;
    height: 30vh !important;
    width: 40vh;
    /* font-size: 0.7vw; */
    border-radius: 7px;
}

.home-navigator {
    display: none;
    padding-top: 7vh;
    text-align: center;
    color: #7f65ff;
}

@media only screen and (max-width: 1024) and (orientation:landscape) {
    textarea .comments-textarea {
        height: 30vh !important;
        width: 20vw;
    }
}

@media only screen and (max-width: 1024px) {
    .home-navigator {
        display: block;
    }
}
