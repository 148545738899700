.aws-btn {
	padding: 0px;
	margin: 0px;
	font-family: "GoodBrush";
	--button-default-height: 50px;
	--button-default-font-size: 3vh;
	--button-default-border-radius: 4px;
	--button-horizontal-padding: 2px;
	--button-raise-level: 7px;
	--button-hover-pressure: 2;
	--transform-speed: 0.15s;
	--button-primary-color: #fcbaa2;
	--button-primary-color-dark: #FF4500;
	--button-primary-color-light: #000000;
	--button-primary-color-hover: rgb(231, 158, 56);
	--button-primary-color-active: #972800;
	--button-primary-border: 0px solid #FFFFFF;
	--button-secondary-color: #65942c;
	--button-secondary-color-dark: #30641f;
	--button-secondary-color-light: #ecf9f8;
	--button-secondary-color-hover: #7CBE2D;
	--button-secondary-color-active: #3F8228;
	--button-secondary-border: 2px solid #30641f;
	--button-anchor-color: #0e4f88;
	--button-anchor-color-dark: #072743;
	--button-anchor-color-light: #ffffff;
	--button-anchor-color-hover: #0d4a7f;
	--button-anchor-color-active: #0c4271;
	--button-anchor-border: none;
	--button-danger-color: #c42e2e;
	--button-danger-color-dark: #861f1f;
	--button-danger-color-light: #ffffff;
	--button-danger-color-hover: #bc2c2c;
	--button-danger-color-active: #af2929;
	--button-danger-border: none;	
}
