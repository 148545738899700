.custom-parallax-container {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.custom-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.section-parallax {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* background-color: #f1f1f1; */
    /* border: 1px solid #ccc; */
    margin: 0;
    padding: 0;
}

.section-1 {
    flex: 1 0 7%;
}

.section-2 {
    flex: 1 0 13%;
}

.section-3 {
    flex: 1 0 80%;
    /* margin-top: 20px; */
}

.slimmer-section {
    padding-right: 15%;
    padding-left: 15%;
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .section-1 {
        flex: 1 0 2%;
    }
    
    .section-2 {
        flex: 1 0 7%;
    }
    
    .section-3 {
        flex: 1 0 88%;
        /* margin-top: 20px; */
    }
    
    .slimmer-section {
        padding-right: 1%;
        padding-left: 1%;
        /* padding-top: 1%; */
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .section-1 {
        flex: 1 0 2%;
    }
    
    .section-2 {
        flex: 1 0 7%;
    }
    
    .section-3 {
        flex: 1 0 88%;
        /* margin-top: 20px; */
    }
    
    .slimmer-section {
        padding-right: 1%;
        padding-left: 1%;
        /* padding-top: 1%; */
    }
}

