.social-contact {
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-icon-div {
    font-family: "SocialShapes"; 
    font-size: 1.4vw;
}

.social-icon-explore-section {
    color: #fcbaa2;
}


@media only screen and (orientation:portrait) and (max-width: 1024px) {
    .social-icon-div {
        font-size: 2.5vh;
    }
}

@media only screen and (orientation:landscape) and (max-width: 1024px) {
    .social-icon-div {
        font-size: 4vh;
    }
}