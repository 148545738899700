.ack-title {
    color: #f1f1f1;
    font-family: "Antihero";
    font-size: '4vmax';
    background-color: '#FFF';
    padding-bottom: "7vh";
    text-align: 'center';
    padding-top: 7vh;
    z-index: 1;
}

.ack-subtitle {
    color: #000;
    font-size: 1.5vmax;
    padding-top: 0.5vmax;
    padding-bottom: 1vmax;
}

.ack-content-block {
    /* position: fixed;
    color: #000;
    font-size: 1.5vmax;
    background-color: #FFF;
    text-align: center;
    margin: 0;
    z-index: 1; */
    /* width: 60%; */
}

.ack-styling-block {
    padding: 0 3% 0 22%;
    width: 50%;
    float: left;
}

.ack-typefaces-block {
    padding: 0 22% 0 3%;
    width: 50%;
    float: right;
}

.ack-url {
    line-height: normal;
    /* padding-top: 10px; */
    margin: 0;
    color: #ff4500 !important;
}

.ack-url:hover {
    text-decoration: underline;
}

.ack-author {
    font-family: "ColoredPencil";
    font-size: 1.7vmax;
}

.ack-home-navigator-desktop {
    font-size: 0.7vmax;
    display: block;
}

.ack-home-navigator-desktop:hover {
    color: orangered !important;
}

.ack-home-navigator-mobile {
    font-size: 0.7vmax;
    display: none;
}

@media only screen and (orientation:portrait) and (max-width: 1024px) {
    
    .ack-title {
        padding-bottom: 7vh;
    }

    .ack-styling-block, .ack-typefaces-block {
        display: block;
        float: none;
        padding: 0;
        width: 100%;
    }

    .ack-styling-block {
        padding: 0 15% 0 15%;
    }
    
    .ack-typefaces-block {
        padding: 5% 15% 0 15%;
    }

    .ack-url {
        font-size: 1.4vh;
    }

    .ack-author {
        font-size: 2vh;
        line-height: 1rem;
    }

    p {
        margin: 0.5rem !important;
    }

    .ack-home-navigator-desktop {
        display: none;
    }
    
    .ack-home-navigator-mobile {
        font-size: 2.5vmax;
        top: 4vmax;
        left: 0.3vmax;
        z-index: 1;
        display: block;
    }
}